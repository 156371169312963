import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Filters, MainPageContent, PageHeader, Table } from '@mv-submodules/inplant-components-fe'
import { AssetsListFilters, CostCenter, ListPagination } from '../../../../types/asset'
import {
  applyAssetsListDeactivatedFilters,
  applyAssetsListDeactivatedPagination,
  fetchCountToValidate,
  fetchDeactivatedAssetListPaginated,
  fetchSitesGroupsModules,
} from '../../../../redux/actions/assetsList'
import {
  FetchDeactivatedAssetsListResponse,
  FetchSitesGrousModulesResponse,
} from '@mv-submodules/inplant-asset-manager-fe/types/fetchData'
import { connect } from 'react-redux'
import { RowInfo } from 'react-table'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'

interface OwnState {
  data?: FetchDeactivatedAssetsListResponse
  isFetching: boolean
  filters: AssetsListFilters
  pagination: ListPagination
  plants: Array<{ code: string; name: string }>
  costCenters: CostCenter[]
  pageType: string
}

interface StateProps {
  filters: AssetsListFilters
  pagination: ListPagination
}

const mapStateToProps = (store: any): StateProps => {
  return {
    filters: store.assetManager.assetsList.deactivatedList.filters,
    pagination: store.assetManager.assetsList.deactivatedList.pagination,
  }
}

interface DispatchProps {
  applyAssetsListDeactivatedFilters: (params: AssetsListFilters) => void
  applyAssetsListDeactivatedPagination: (params: ListPagination) => void
  fetchCountToValidate: () => void
}

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  applyAssetsListDeactivatedFilters: (params: AssetsListFilters) => dispatch(applyAssetsListDeactivatedFilters(params)),
  applyAssetsListDeactivatedPagination: (params: ListPagination) =>
    dispatch(applyAssetsListDeactivatedPagination(params)),
  fetchCountToValidate: () => dispatch(fetchCountToValidate()),
})

type Props = StateProps & DispatchProps & RouteComponentProps & WithTranslation

class DeactivatedAssetListPageView extends React.Component<Props, OwnState> {
  private abortController = new AbortController()

  constructor(props: Props) {
    super(props)
    this.state = {
      data: undefined,
      isFetching: false,
      filters: {
        ...this.props.filters,
      },
      pagination: {
        ...this.props.pagination,
      },
      plants: [],
      costCenters: [],
      pageType: '',
    }
    this.applyFiltersTable = this.applyFiltersTable.bind(this)
    this.applyPaginationListTable = this.applyPaginationListTable.bind(this)
    this.onPageSizeChange = this.onPageSizeChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onSortingChange = this.onSortingChange.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.onFetchData = this.onFetchData.bind(this)
    this.updateSearchString = this.updateSearchString.bind(this)
    this.changeCostCenter = this.changeCostCenter.bind(this)
  }

  public componentDidMount() {
    this.fetchData()
    this.props.fetchCountToValidate()
  }

  public render() {
    const { t } = this.props
    const { data, isFetching, filters, pagination } = this.state
    const pages = data ? Math.ceil(data.metadata.count / data.metadata.pageSize) : null
    const filterElements: FilterComponent[] = [
      {
        type: 'SearchInput',
        name: 'search-asset',
        id: 'search-asset',
        label: t('assetManager.filters.searchPlaceholderAsset'),
        value: filters.searchString,
        throttle: 500,
        disabled: isFetching || !data,
        onChange: (name, value) => this.updateSearchString((value && (value as string)) || null),
      },
      {
        type: 'Select',
        name: 'costCenter',
        id: 'select-cost-center',
        value: filters.costCenter,
        label: t('assetManager.filters.allCostCenter'),
        options: {
          defaultOptionDisabled: false,
          defaultOptionLabel: t('assetManager.filters.allCostCenter'),
          items:
            this.state.costCenters &&
            this.state.costCenters.map(costCenter => ({
              value: costCenter.code,
              label: costCenter.name,
            })),
        },
        disabled: isFetching || !data,
        onChange: (name, value) => this.changeCostCenter((value && (value as string)) || ''),
      },
    ]
    return (
      <div className="inplant-asset-manager-fe">
        {/* Header Section*/}
        <PageHeader title={t(`assetManager.navigation.deactivatedAssets`)} />
        {/* End Header Section*/}
        {/* Content Section*/}
        <MainPageContent>
          <Filters fields={filterElements} />
          <Table
            columns={[
              {
                Cell: ({ original }: RowInfo) => original.code,
                Header: t('assetManager.table.header.machineCode'),
                accessor: 'code',
                sortable: true,
                id: 'code',
              },
              {
                Cell: ({ original }: RowInfo) => original.description,
                Header: t('assetManager.table.header.assetName'),
                accessor: 'description',
                sortable: true,
              },
              {
                Cell: ({ original }: RowInfo) => original?.assetData?.brand,
                Header: t('assetManager.table.header.brand'),
                accessor: 'brand',
                sortable: true,
              },
              {
                Cell: ({ original }: RowInfo) => original?.assetData?.model,
                Header: t('assetManager.table.header.assetData.model'),
                accessor: 'assetData.model',
                sortable: true,
              },
              {
                Cell: ({ original }: RowInfo) => original?.assetData?.serialNumber,
                Header: t('assetManager.table.header.assetData.serialNumber'),
                accessor: 'assetData.serialNumber',
                sortable: true,
              },
              {
                Cell: ({ original }: RowInfo) => original.costCenter.name,
                Header: t('assetManager.table.header.costCenter'),
                accessor: 'costCenter.name',
                sortable: true,
              },
            ]}
            manualIsFetching={true}
            isFetching={isFetching}
            noDataText={t(
              `assetManager.table.${Object.keys(filters).some(key => !filters[key]) ? 'noItemFound' : 'noData'}`
            )}
            ofText={t('assetManager.table.of')}
            rowsText={t('assetManager.table.rows')}
            pageText={t('assetManager.table.page')}
            data={(data && data.result) || []}
            pageSize={pagination ? pagination.pageSize : (data && data.result.length) || 0}
            page={pagination ? pagination.pageNumber : 0}
            pages={pages || 0}
            manual={true}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
            onFetchData={this.onFetchData}
            defaultSorted={
              this.props.pagination && this.props.pagination.sortField && this.props.pagination.sortDirection
                ? [
                    {
                      id: this.props.pagination.sortField,
                      desc: this.props.pagination.sortDirection === 'desc',
                    },
                  ]
                : undefined
            }
          />
        </MainPageContent>
        {/* End Content Section*/}
      </div>
    )
  }

  private applyFiltersTable(filters: AssetsListFilters) {
    if (!this.state.isFetching) {
      this.setState({
        filters,
        pagination: {
          ...this.state.pagination,
          pageNumber: 0,
        },
      })
      this.props.applyAssetsListDeactivatedFilters(filters)
      this.applyPaginationListTable()
    }
  }

  private applyPaginationListTable() {
    this.props.applyAssetsListDeactivatedPagination(this.state.pagination)
    this.fetchData()
  }

  private async fetchData() {
    if (this.state.isFetching) {
      this.abortController.abort()
      this.abortController = new AbortController()
    }

    try {
      this.setState({
        isFetching: true,
      })
      const sitesData: FetchSitesGrousModulesResponse = await fetchSitesGroupsModules()
      const plantsArray: Array<{ code: string; name: string }> = sitesData.sites.reduce(
        (acc: Array<{ code: string; name: string }>, sites) => {
          acc = acc.concat(
            sites.plants.reduce((plants: Array<{ code: string; name: string }>, plant) => {
              plants.push({
                code: plant.code,
                name: plant.name,
              })
              return plants
            }, [])
          )
          return acc
        },
        []
      )

      const costCenters: CostCenter[] = sitesData.sites.reduce((acc: CostCenter[], site) => {
        acc = acc.concat(
          site.plants.reduce((acc2: CostCenter[], plant) => {
            acc2 = acc2.concat(plant.costCenters)
            return acc2
          }, [])
        )
        return acc
      }, [])
      const data = await fetchDeactivatedAssetListPaginated(
        this.abortController,
        this.state.filters,
        this.state.pagination
      )
      this.setState({
        plants: plantsArray,
        costCenters,
        isFetching: false,
        data: {
          ...data,
          metadata: {
            ...data.metadata,
            pageNumber: data.metadata.pageNumber - 1,
          },
        },
        pagination: {
          ...this.state.pagination,
          pageSize: data.metadata.pageSize,
          pageNumber: data.metadata.pageNumber - 1,
          sortField: data.metadata.sortField,
          sortDirection: data.metadata.sortDirection,
        },
      })
    } catch (error: any) {
      if (!(error.name && error.name === 'AbortError')) {
        console.warn('error', error) // tslint:disable-line
        this.setState({
          isFetching: false,
        })
      }
    }
  }

  private onPageSizeChange(pageSize: number) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageSize,
        },
      },
      this.applyPaginationListTable
    )
  }

  private onPageChange(pageNumber: number) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          pageNumber: pageNumber + 1,
        },
      },
      this.applyPaginationListTable
    )
  }

  private onSortingChange(sortField: string, sortDirection: string) {
    if (
      !this.state.isFetching &&
      (this.state.pagination.sortDirection !== sortDirection || this.state.pagination.sortField !== sortField)
    ) {
      this.setState(
        {
          pagination: {
            ...this.state.pagination,
            sortField,
            sortDirection,
          },
        },
        this.applyPaginationListTable
      )
    }
  }

  private onFetchData(state: any, instance: any) {
    const [sorting] = state.sorted
    if (!this.state.isFetching && sorting) {
      this.onSortingChange(sorting.id, sorting.desc ? 'desc' : 'asc')
    }
  }

  private updateSearchString(searchString: string | null) {
    this.applyFiltersTable({
      ...this.state.filters,
      searchString,
    })
  }

  private changeCostCenter(costCenter: string) {
    this.applyFiltersTable({
      ...this.state.filters,
      costCenter,
    })
  }
}

export default withRouter<any, any>(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeactivatedAssetListPageView))
)
